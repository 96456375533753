<template>
  <v-dialog
    content-class="white-two"
    max-width="800px"
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
  >
    <v-card class="pa-4">
      <v-card-title class="pa-2">
        <div>
          <v-icon color="default" @click="closeDialog()">close</v-icon>
          <br />
        </div>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-6 pb-8">
          <v-col cols="3" class="mr-1">
            <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '128' : '64'">
              <img
                v-if="profileData?.thumbnail"
                :src="profileData?.thumbnail"
              />
              <img v-else src="@/assets/img/matching-placeholder.jpg" />
            </v-avatar>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.mdAndUp ? '6' : '8'"
            :class="$vuetify.breakpoint.mdAndUp ? 'pt-5' : 'pt-2'"
          >
            <v-row>
              <v-col
                cols="1"
                :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-2'"
              >
                <TiktokIcon />
              </v-col>
              <v-col class="subheading">
                <a
                  :href="profileData.tiktokURL"
                  target="_blank"
                  style="color: #000000"
                  >{{
                    `${profileData.nickname || ""} @${profileData.id || ""}`
                  }}</a
                >
              </v-col>
            </v-row>
            <v-row>
              <!-- 
                category
                <v-chip x-small outlined color="#34CD89" class="ma-0 mr-1 mt-2"
                        v-for="(category, idx) in _.take(_.sortBy(_.toPairs(openYoutuber.categories), 1).reverse(), 2)"
                        :key="idx">{{ category[0] + ' ' + Math.round(category[1]) + '%' }}
                </v-chip> -->
            </v-row>
            <v-row>
              <v-list two-line class="pa-0">
                <v-list-item class="pa-0">
                  <v-list-item-content class="pt-1">
                    <v-list-item-title style="font-size: 10px; color: #808080"
                      >팔로워</v-list-item-title
                    >
                    <v-list-item-subtitle class="caption"
                      >{{ profileData?.followerCount }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="pt-1">
                    <v-list-item-title style="font-size: 10px; color: #808080"
                      >팔로잉</v-list-item-title
                    >
                    <v-list-item-subtitle class="caption">{{
                      profileData?.followingCount
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="pt-1">
                    <v-list-item-title style="font-size: 10px; color: #808080"
                      >총 콘텐츠</v-list-item-title
                    >
                    <v-list-item-subtitle class="caption">{{
                      profileData?.totalVideoCount
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <!-- <v-list-item-content class="pt-1">
                    <v-list-item-title style="font-size: 10px; color: #808080"
                      >수락률/평점</v-list-item-title
                    >
                    <v-list-item-subtitle class="caption"
                      >{{ (33 || "-") + "%" }}/<span style="color: #f0cb30">{{
                        "★" + (33 || "-")
                      }}</span></v-list-item-subtitle
                    >
                  </v-list-item-content> -->
                </v-list-item>
              </v-list>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-row class="pl-3">
              <v-icon>mdi-star-outline</v-icon>
              <!-- <v-icon
                :color="checkFavorite(openYoutuber) ? 'pink' : ''"
                @click="setFavorite(openYoutuber)"
                >{{
                  checkFavorite(openYoutuber) ? "mdi-star" : "mdi-star-outline"
                }}</v-icon -->
            </v-row>
            <v-row> 즐겨찾기 </v-row>
          </v-col>
        </v-row>

        <v-tabs v-model="tab">
          <v-tab>채널 통계</v-tab>
          <v-tab>최근 영상</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="mt-4">
          <v-tab-item>
            <Statistics :detailTargetId="detailTargetId" />
          </v-tab-item>
          <v-tab-item>
            <LatestVideo :detailTargetId="detailTargetId" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import campaignMixin from "@/components/shared/campaignMixin";
import Statistics from "./tiktok/Statistics.vue";
import LatestVideo from "./tiktok/LatestVideo.vue";

export default {
  name: "TiktokDetail",
  mixins: [campaignMixin],
  props: ["detailTargetId"],
  components: {
    TiktokIcon: () => import("@/assets/img/campaign/tiktok.svg"),
    Statistics,
    LatestVideo,
  },
  data() {
    return {
      tab: null,
      profileData: {},
    };
  },
  async created() {
    const {
      data: { data },
    } = await this.axios.get(`/tiktoker/info?id=${this.detailTargetId}`);

    this.profileData = data;
  },
  methods: {
    closeDialog() {
      this.$emit("closeDetailDlg");
    },
  },
};
</script>

<style scoped lang="scss"></style>
