var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"youtuber-detail__subtitle font-weight-bold px-4 mb-4",staticStyle:{"font-size":"14px"}},[_vm._v(" 최근 영상 ")]),_c('v-row',[_c('v-data-table',{staticStyle:{"backgroud-color":"transparent","border-radius":"0","border-top":"1px solid rgba(0, 0, 0, 0.12)","border-bottom":"1px solid rgba(0, 0, 0, 0.12)","width":"100%"},attrs:{"height":"400","fixed-header":"","headers":[
        { text: '영상', value: 'thumbnail', sortable: false },
        { text: '제목', value: 'title', sortable: false },
        { text: '조회수', value: 'viewCount', sortable: true, width: '88px' },
        { text: '좋아요', value: 'likeCount', sortable: true, width: '88px' },
        {
          text: '댓글수',
          value: 'commentCount',
          sortable: true,
          width: '88px',
        },
      ],"items":_vm.videos,"items-per-page":30,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.thumbnail`,fn:function({ item }){return [_c('a',{attrs:{"href":item.videoURL,"target":"_blank"}},[_c('div',{staticClass:"mx-1",staticStyle:{"maxwidth":"128px"}},[(!!item?.thumbnail)?_c('img',{staticStyle:{"width":"60px","height":"108px"},attrs:{"src":item.thumbnail}}):_c('img',{staticStyle:{"width":"60px","height":"108px"},attrs:{"src":require("@/assets/img/matching-placeholder.jpg")}})])])]}},{key:`item.viewCount`,fn:function({ item }){return [_vm._v(" "+_vm._s(item?.viewCount)+" ")]}},{key:`item.likeCount`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item?.likeCount)+" ")])]}},{key:`item.commentCount`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item?.commentCount)+" ")])]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }