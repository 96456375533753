import { render, staticRenderFns } from "./TiktokDetail.vue?vue&type=template&id=ca4a75ee&scoped=true"
import script from "./TiktokDetail.vue?vue&type=script&lang=js"
export * from "./TiktokDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca4a75ee",
  null
  
)

export default component.exports