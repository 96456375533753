<template>
  <v-col v-if="!isShowMobileFilterSelect && !isSHowMobileOrderSelect">
    <v-row>
      <v-card
        flat
        :style="
          $vuetify.breakpoint.mdOnly
            ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;'
            : 'width:1120px'
        "
      >
        <v-data-table
          :headers="headers"
          :items="resultData.list"
          item-key="id"
          :items-per-page="options.itemsPerPage"
          :options.sync="options"
          :server-items-length="resultData.total"
          :footer-props="{
            itemsPerPageOptions: [],
          }"
          disable-sort
          mobile-breakpoint="0"
          :hide-default-header="$vuetify.breakpoint.smAndDown"
          hide-default-footer
          style="backgroud-color: transparent"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                ref="keywordRef"
                rounded
                dense
                solo
                flat
                hide-details
                prepend-icon="search"
                @keyup.enter="requestTiktokList(true)"
                placeholder="광고 제안을 원하는 틱톡커를 검색해보세요."
                class="d-flex align-center"
              >
                <v-btn
                  slot="append"
                  class="white-two--text"
                  color="#BD1328"
                  elevation="3"
                  @click="requestTiktokList(true)"
                >
                  검색
                </v-btn>
              </v-text-field>
            </v-toolbar>
            <div
              style="background-color: #f2f2f2; width: 100%; height: 16px"
            ></div>
            <v-toolbar flat>
              <div class="body-2 mr-4" style="color: #808080">
                <span
                  class="subheading mr-4 font-weight-bold"
                  style="color: #484848"
                  >틱톡커 목록
                </span>
                <span v-if="$vuetify.breakpoint.mdAndUp">검색 결과 </span
                >{{ resultData.total }}명 ・
                {{ parseInt(resultData.total / options.itemsPerPage) }} Pages
              </div>
            </v-toolbar>
            <v-divider></v-divider>
            <v-expansion-panels v-model="optionPanelIdx" flat readonly>
              <v-expansion-panel>
                <v-expansion-panel-header
                  hide-actions
                  class="pl-3"
                  style="display: block"
                >
                  <v-row v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col>
                      <v-chip
                        v-for="filter in filters"
                        :key="filter.name"
                        :class="
                          selectedFilterName === filter.name
                            ? 'white-two--text'
                            : ''
                        "
                        :disabled="!filter.options.length"
                        :color="
                          selectedFilterName === filter.name ||
                          (filter.sel &&
                            filter.sel.length > 0 &&
                            filter.sel.indexOf(0) === -1)
                            ? 'rouge'
                            : '#F5F5F5'
                        "
                        :outlined="
                          filter.sel &&
                          filter.sel.length > 0 &&
                          filter.sel.indexOf(0) === -1 &&
                          selectedFilterName !== filter.name
                        "
                        style="color: #484848; font-size: 13px"
                        @click="filterExpand(filter)"
                      >
                        {{
                          filter.sel.length > 0 && filter.sel.indexOf(0) === -1
                            ? filter.name + " " + filter.sel.length + "개"
                            : filter.name
                        }}
                        <v-icon small>{{ filter.icon }}</v-icon>
                      </v-chip>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            color="#F5F5F5"
                            v-bind="attrs"
                            v-on="on"
                            style="color: #484848; font-size: 13px"
                            @click="filterExpand(-1)"
                          >
                            {{ options.itemsPerPage }}명씩 보기
                            <v-icon small>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in [20, 50, 100]"
                            :key="index"
                            @click="options.itemsPerPage = item"
                          >
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            color="#F5F5F5"
                            v-bind="attrs"
                            v-on="on"
                            style="color: #484848; font-size: 13px"
                            @click="filterExpand(-1)"
                          >
                            {{ orderBy[orderIdx].name }}
                            <v-icon small>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in orderBy"
                            :key="index"
                            @click="orderIdx = index"
                          >
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <!-- <v-chip
                        @click="
                          favorite = !favorite;
                          filterExpand(-1);
                        "
                        :class="favorite ? 'white-two--text' : ''"
                        :color="favorite ? 'rouge' : '#F5F5F5'"
                        style="color: #484848; font-size: 13px"
                      >
                        즐겨찾기
                      </v-chip> -->
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        color="rouge"
                        class="ma-0 white-two--text"
                        tile
                        flat
                        @click="requestTiktokList(true)"
                      >
                        필터 적용
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-chip @click="filterExpandMobile('filter')">
                      필터
                      <v-icon small>mdi-tune</v-icon>
                    </v-chip>
                    <v-chip @click="filterExpandMobile('order')">
                      정렬
                      <v-icon small>mdi-sort-variant</v-icon>
                    </v-chip>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-chip-group
                      v-if="!!selectedFilterName"
                      v-model="filters[selectedFilterName].sel"
                      column
                      multiple
                      active-class="active white--text"
                    >
                      <v-chip
                        v-for="(option, index) in filters[selectedFilterName]
                          .options"
                        :key="index"
                        :outlined="
                          filters[selectedFilterName].sel.indexOf(index) === -1
                        "
                        :color="
                          filters[selectedFilterName].sel.indexOf(index) > -1
                            ? 'rouge'
                            : ''
                        "
                        style="
                          color: #484848;
                          font-size: 13px;
                          pointer-events: auto;
                        "
                      >
                        {{ option.text }}
                      </v-chip>
                    </v-chip-group>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-divider></v-divider>
          </template>
          <template v-slot:[`header.check`]="{}">
            <v-icon dense class="mb-1">mdi-check-circle</v-icon>
          </template>
          <template v-slot:[`header.id`]="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{
              header.text
            }}</span>
          </template>
          <template v-slot:[`header.followerCount`]="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{
              header.text
            }}</span>
          </template>
          <template v-slot:[`header.avgViewsPerMonth`]="{ header }">
            <span style="font-size: 10px; font-weight: normal">
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`header.avgVideoCountPerMonth`]="{ header }">
            <span style="font-size: 10px; font-weight: normal">
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`header.favorite`]="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{
              header.text
            }}</span>
          </template>
          <template v-slot:[`item.check`]="{}">
            <v-row>
              <v-icon :dense="$vuetify.breakpoint.mdAndUp" class="mb-1"
                >mdi-check-circle</v-icon
              >
            </v-row>
          </template>
          <template v-slot:[`item.id`]="props">
            <v-row
              @click="tiktokDetailDlg(props.item)"
              class="row py-2 pt-3 row-pointer"
            >
              <v-col>
                <div class="d-flex align-center">
                  <v-avatar size="40">
                    <img
                      v-if="props.item.thumbnail"
                      :src="props.item.thumbnail"
                    />
                    <img v-else src="@/assets/img/matching-placeholder.jpg" />
                  </v-avatar>
                  <div class="ml-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ props.item.id }}</span>
                      </template>
                      <span>{{ props.item.nickname }}</span>
                    </v-tooltip>
                    <div>
                      <v-chip
                        outlined
                        x-small
                        color="#34CD89"
                        class="ma-0 mr-1 mb-1"
                      >
                        {{ props.item.category }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="">
                <v-row
                  style="padding-bottom: 4px; padding-top: 4px; font-size: 13px"
                  class="hidden-md-and-up pb-2"
                >
                  <v-col>
                    <div>1개월 평균 조회수</div>
                    {{ props.item.avgViewsPerMonth }}
                  </v-col>
                  <v-col>
                    <div>1개월 컨텐츠 수</div>
                    {{ props.item.avgVideoCountPerMonth }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.favorite`]="{ item }">
            <v-icon
              @click="toggleFavorite(item)"
              :color="item.isFavorite ? 'pink' : ''"
              >{{ item.isFavorite ? "mdi-star" : "mdi-star-outline" }}</v-icon
            >
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination
            v-model="options.page"
            :length="Math.floor(resultData.total / options.itemsPerPage + 1)"
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card>
    </v-row>
    <!-- youtuber detail -->
    <v-row justify="center">
      <TiktokDetail
        v-if="isShowDetail"
        :detailTargetId="detailTargetId"
        @closeDetailDlg="closeDetailDlg"
      />
    </v-row>
  </v-col>
  <v-col v-else-if="isShowMobileFilterSelect" style="margin-bottom: -24px">
    <v-toolbar flat>
      <div @click="isShowMobileFilterSelect = false">&#60; 필터 선택</div>
    </v-toolbar>
    <v-row v-for="(filter, idx) in filters" :key="idx">
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row>
          {{ filter.name }}
        </v-row>
        <v-row>
          <v-chip-group
            v-model="filter.sel"
            column
            multiple
            active-class="active white--text"
          >
            <v-chip
              v-for="(option, index) in filter.options"
              :key="index"
              @click="expand = false"
              :outlined="filter.sel.indexOf(index) === -1"
              :color="filter.sel.indexOf(index) > -1 ? 'rouge' : ''"
              style="color: #484848; font-size: 13px; pointer-events: auto"
            >
              {{ option.text }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-4" style="background-color: white">
      <v-col cols="6">
        <v-btn
          color="#757575"
          class="ma-0 white-two--text"
          width="100%"
          tile
          flat
          style="font-size: 13px"
          @click="
            () => {
              filters.forEach((filter) => {
                filter.sel = [];
              });
            }
          "
        >
          초기화
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="rouge"
          class="ma-0 white-two--text"
          width="100%"
          tile
          flat
          style="font-size: 13px"
          @click="isShowMobileFilterSelect = false"
        >
          선택 적용
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
  <v-col v-else style="margin-bottom: -24px">
    <v-toolbar flat>
      <div @click="isSHowMobileOrderSelect = false">&#60; 정렬 기준</div>
    </v-toolbar>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row> 정렬 순서 </v-row>
        <v-row>
          <v-chip
            v-for="(item, index) in orderBy"
            :key="index"
            @click="orderIdx = index"
            :class="orderIdx === index ? 'white-two--text' : ''"
            :outlined="orderIdx !== index"
            :color="orderIdx === index ? 'rouge' : ''"
            style="color: #484848; font-size: 13px"
          >
            {{ item.name }}
          </v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row> 한 페이지에 보이는 항목 수 </v-row>
        <v-row>
          <v-chip
            v-for="(item, index) in ['20', '50', '100']"
            :key="index"
            @click="options.itemsPerPage = item"
            :class="options.itemsPerPage === item ? 'white-two--text' : ''"
            :outlined="options.itemsPerPage !== item"
            :color="options.itemsPerPage === item ? 'rouge' : ''"
            style="color: #484848; font-size: 13px"
          >
            {{ item }}
          </v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-4" style="background-color: white">
      <v-col cols="6">
        <v-btn
          color="#757575"
          class="ma-0 white-two--text"
          width="100%"
          tile
          flat
          style="font-size: 13px"
          @click="
            options.itemsPerPage = 20;
            orderIdx = 0;
          "
        >
          초기화
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="rouge"
          class="ma-0 white-two--text"
          width="100%"
          tile
          flat
          style="font-size: 13px"
          @click="showSortSelect = false"
        >
          선택 적용
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import userMixin from "@/components/shared/userMixin";
import campaignMixin from "@/components/shared/campaignMixin";
import TiktokDetail from "../../components/campaign/dialog/TiktokDetail.vue";

export default {
  name: "SearchTiktok",
  mixins: [userMixin, campaignMixin],
  components: {
    TiktokDetail,
  },
  data() {
    return {
      headers_desktop: [
        { text: "", value: "check" },
        { text: "틱톡커 정보", value: "id", width: "280px" },
        { text: "팔로워수", value: "followerCount" },
        { text: "최근 1개월 평균 조회수", value: "avgViewsPerMonth" },
        { text: "최근 1개월 평균 컨텐츠 수", value: "avgVideoCountPerMonth" },
        { text: "즐겨찾기", value: "favorite" },
      ],
      headers_mobile: [
        { text: "", value: "check" },
        { text: "틱톡커 정보", value: "id", width: "280px" },
        { text: "팔로워수", value: "followerCount" },
      ],
      filters: {
        카테고리: {
          name: "카테고리",
          icon: "expand_more",
          query: "categories[]",
          options: [
            { text: "전체", value: "" },
            { text: "코미디", value: "코미디" },
            { text: "댄스", value: "댄스" },
            { text: "뷰티", value: "뷰티" },
            { text: "패션", value: "패션" },
            { text: "음식", value: "음식" },
            { text: "운동", value: "운동" },
            { text: "DIY", value: "DIY" },
            { text: "패러디", value: "패러디" },
            { text: "동물", value: "동물" },
            { text: "게임", value: "게임" },
            { text: "여행", value: "여행" },
            { text: "영화", value: "영화" },
            { text: "드라마", value: "드라마" },
            { text: "애니", value: "애니" },
            { text: "음악", value: "음악" },
            { text: "교육", value: "교육" },
            { text: "정보", value: "정보" },
            { text: "예술", value: "예술" },
            { text: "과학", value: "과학" },
            { text: "ASMR", value: "ASMR" },
            { text: "챌린지", value: "챌린지" },
            { text: "건강", value: "건강" },
            { text: "사회/정치", value: "사회/정치" },
            { text: "스포츠", value: "스포츠" },
            { text: "육아", value: "육아" },
            { text: "일상", value: "일상" },
            { text: "가구", value: "가구" },
            { text: "독서", value: "독서" },
            { text: "IT/전자기기", value: "IT/전자기기" },
          ],
          sel: [0],
        },
        "팔로워 수": {
          name: "팔로워 수",
          icon: "expand_more",
          query: "followerCount[]",
          options: [
            {
              text: "전체",
              value: "",
            },
            {
              text: "10,000-30,000",
              value: "10000-30000",
            },
            {
              text: "30,000-100,000",
              value: "30000-100000",
            },
            {
              text: "100,000-300,000",
              value: "100000-300000",
            },
            {
              text: "300,000-1,000,000",
              value: "300000-1000000",
            },
            {
              text: "1,000,000 이상",
              value: "1000000",
            },
          ],
          sel: [0],
        },
        "최근 1개월 평균 조회수": {
          name: "최근 1개월 평균 조회수",
          icon: "expand_more",
          query: "avgViewsPerMonth[]",
          options: [
            {
              text: "전체",
              value: "",
            },
            {
              text: "10,000-30,000",
              value: "10000-30000",
            },
            {
              text: "30,000-100,000",
              value: "30000-100000",
            },
            {
              text: "100,000-300,000",
              value: "100000-300000",
            },
            {
              text: "300,000-1,000,000",
              value: "300000-1000000",
            },
            {
              text: "1,000,000 이상",
              value: "1000000",
            },
          ],
          sel: [0],
        },
        "최근 1개월 컨텐츠 수": {
          name: "최근 1개월 컨텐츠 수",
          icon: "expand_more",
          query: "avgVideoCountPerMonth[]",
          options: [
            {
              text: "전체",
              value: "",
            },
            {
              text: "10-20",
              value: "10-20",
            },
            {
              text: "20-30",
              value: "20-30",
            },
            {
              text: "30 이상",
              value: "30",
            },
          ],
          sel: [0],
        },
      },
      selectedFilterName: "",
      optionPanelIdx: null,
      options: { page: 1, itemsPerPage: 20 },
      orderBy: [
        {
          name: "팔로워 많은 순",
          value: "followerCount-desc",
        },
        {
          name: "팔로워 적은 순",
          value: "followerCount-asc",
        },
      ],
      orderIdx: 0,
      resultData: {
        list: [],
        total: 0,
      },
      isShowMobileFilterSelect: false,
      isSHowMobileOrderSelect: false,
      isShowDetail: false,
      detailTargetId: null,
    };
  },
  computed: {
    headers() {
      return this.$vuetify.breakpoint.mdAndUp
        ? this.headers_desktop
        : this.headers_mobile;
    },
  },
  methods: {
    filterExpand(filter) {
      if (filter === -1) {
        this.optionPanelIdx = 1;
        return;
      }

      //PC
      if (this.selectedFilterName === filter.name) {
        this.selectedFilterName = null;
        this.optionPanelIdx = 1;
      } else {
        this.selectedFilterName = filter.name;
        this.optionPanelIdx = 0;
      }
    },

    filterExpandMobile(target) {
      if (target === "filter") {
        this.isShowMobileFilterSelect = true;
      } else if (target === "order") {
        this.isSHowMobileOrderSelect = true;
      }
    },
    generatedQueries() {
      const [orderBy, direction] = this.orderBy[this.orderIdx].value.split("-");
      const cloneFilters = { ...this.filters };
      const filter = Object.values(cloneFilters).reduce((acc, value) => {
        const { sel, options, query } = value;

        if (sel.includes(0)) {
          return acc;
        }

        const values = sel.map((index) => options[index].value);
        acc[query] = values;
        return acc;
      }, []);

      const keyword = this.$refs.keywordRef?.internalValue;

      const { page, itemsPerPage: limit } = { ...this.options };

      const queries = Object.entries({
        orderBy,
        direction,
        limit,
        page: page - 1,
        ...filter,
        ...(!!keyword && { keyword }),
      })
        .flatMap(([key, value]) => {
          if (/\[\]/.test(key)) {
            return value.map((v) => `${key}=${v}`);
          } else {
            return `${key}=${value}`;
          }
        })
        .join("&");

      return queries;
    },
    async requestTiktokList(isPageInit) {
      if (isPageInit) {
        this.options.page = 1;
      }

      const queries = this.generatedQueries();
      const { data: response } = await this.axios.get(`/tiktoker?${queries}`);
      const { data: list, total } = response;
      this.resultData = { total, list: list };
    },
    async toggleFavorite(item) {
      console.log(item);
    },
    tiktokDetailDlg(target) {
      this.isShowDetail = true;
      this.detailTargetId = target.id;
    },
    closeDetailDlg() {
      this.isShowDetail = false;
      this.detailTargetId = null;
    },
  },
  watch: {
    options: {
      handler() {
        this.requestTiktokList();
      },
      deep: true,
    },
    orderIdx: {
      handler() {
        this.requestTiktokList();
      },
    },
  },
  created() {
    this.requestTiktokList();
  },
};
</script>

<style scoped lang="scss"></style>
